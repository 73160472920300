body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #f9fafb;
  /* padding-bottom: 40%; */
}

h1.title {
  border-bottom: 2px solid #84cc2b;
  border-radius: 15px;
  width: 460px;
  margin: 30px auto 60px;
}

h1.title:after {
  content: "";
  position: absolute;
  bottom: calc(-100% - 1px);
  margin-left: 0px;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border-top: 2px solid #84cc2b;
}

/* h1.title:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 60px;
  bottom: 0;
  left: 48%;
  background-image: url(../src/images/shape.png);
} */

.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  /* box-shadow: 0px 0px 25px #fec135; */
}

.sidebar {
  background: rgb(249 250 251);
  position: fixed;
  top: 125px;
  left: 0;
}

.full-body {
  padding-left: 110px;
  padding-top: 90px;
}

.nav-tabs .nav-link.active {
  color: #000 !important;
  border-color: #2563eb;
}

.nav-tabs .nav-link {
  color: #000 !important;
}

.main-nav select {
  background-color: #ffc107;
  border: none;
}

.main-nav select option[value="1"] {
  color: #ffc107;
}

.main-nav select option[value="2"] {
  color: #28a745;
}

.main-nav select option[value="3"] {
  color: #ff0000;
}

.main-nav select option[value="4"] {
  color: #007bff;
}

.main-nav select option[value="5"] {
  color: #17a2b8;
}

.main-nav select option {
  border-bottom: 1px solid gray;
}

.main-page {
  background-image: url(../src/Assets/Images/sign-ip.png);
  background-size: 100% 100%;
  background-position: center;
}

.main-page-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.sign-up {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
